<template>
  <div>
    <div
      v-if="loading"
      class="flex min-h-[80vh] flex-col items-center justify-center"
    >
      <img
        src="~/assets/img/truhub_login.svg"
        class="-mt-12 mb-4 w-full select-none px-6 md:w-1/3"
        alt="Picture of a magical system"
      >
      <div class="flex items-center justify-center">
        <UIcon
          name="i-line-md-loading-loop"
          class="mr-4 text-4xl text-trublue"
        />
        <h2 class="text-xl font-medium">
          {{ $t('dashboards.loading') }}
        </h2>
      </div>
    </div>

    <template v-else-if="currentDashboard && Object.hasOwn(currentDashboard, 'config') && userHasPermission">
      <DashboardFilterBar
        :dashboard-id="currentDashboard.id"
        :organisation-id="currentDashboard.rootOrganisationNodeId"
      />

      <NuxtPage
        :page-key="route => route.fullPath"
        :dashboard-object="currentDashboard"
      />
    </template>

    <HubError
      v-else-if="!userHasPermission || showDashboardError"
      :custom-message="dashboardErrorMessage"
      :status-code="showDashboardError ? 404 : 403"
    />
  </div>
</template>

<script lang="ts" setup>
import type { HubCommentDashboard, HubDashboard } from '~/types/configuration'

const { t } = useI18n()
const route = useRoute()
const dashboardStore = useDashboardStore()
const { fetchDashboard, setCurrentDashboardId } = dashboardStore
const { currentDashboard } = storeToRefs(dashboardStore)

const userHasPermission = ref(true)
const showDashboardError = ref(false)
const dashboardErrorMessage = ref(t('dashboards.errors.403'))
const loading = ref(true)

watch(
  () => route.params.id,
  async () => {
    loading.value = true
    clearError()

    try {
      if (!route.params.id || route.params.id === 'undefined') {
        loading.value = false
        showDashboardError.value = true
        dashboardErrorMessage.value = t('dashboards.errors.404')
        return
      }

      const dashboard: HubDashboard | HubCommentDashboard = await fetchDashboard(route.params.id as string)
      if (!dashboard) {
        loading.value = false
        showDashboardError.value = true
        dashboardErrorMessage.value = t('dashboards.errors.404')
        return
      }

      let permissionRequired: string = 'Dashboards.Default.Organisation.Read'
      const dashboardType: string = dashboard.type ? capitalise(dashboard.type) : 'Default'

      if (dashboardType === 'Default') {
        permissionRequired = dashboard.isOwner ? 'Dashboards.Default.Self.Read' : 'Dashboards.Default.Organisation.Read'
      } else {
        permissionRequired = `Dashboards.${dashboardType}.Read`
      }

      userHasPermission.value = checkPermissions({ permissions: [permissionRequired], allRequired: false })
      setCurrentDashboardId(route.params.id as string)
    } catch (error:
      { statusCode: number, response: { _data: { errors: { dashboardId: string[] } } } }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- any is used to catch all errors
      | any
    ) {
      showDashboardError.value = true

      if ((error?.statusCode === 404)
        || (error?.statusCode === 400
          && error.response._data.errors.dashboardId
          && error.response._data.errors.dashboardId.includes('The input was not valid.'))) {
        dashboardErrorMessage.value = t('dashboards.errors.404')
      } else if (error?.statusCode === 403) {
        dashboardErrorMessage.value = t('dashboards.errors.403')
      } else {
        throw createError({
          statusCode: 500,
          statusMessage: t('dashboards.errors.404'),
          fatal: true
        })
      }
    }

    loading.value = false
  },
  {
    immediate: true,
    deep: true
  }
)
</script>
